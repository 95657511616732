import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import ImageFinancing from './assets/image-financing.webp';
import ImageMoneyBack from './assets/image-money-back.webp';
import ImageWarranty from './assets/image-warranty.webp';

import styles from './part-benefits.module.scss';

export default function PartBenefits() {
  const benefits = [
    {
      imageUrl: ImageFinancing,
      title: 'Financing Available',
      text: 'SmartTech offers a fast, flexible and easy way to pay for your window and door replacement project through the Manitoba Hydro Home Energy Efficiency Loan.',
    },
    {
      imageUrl: ImageWarranty,
      title: 'Lifetime warranty',
      text: 'We are confident in the durability of our windows and doors! Any components that fail for any reason, will be promptly replaced or repaired.',
    },
  ];
  return (
    <div className={styles.partBenefits}>
      <div className={styles.partBenefits__blockTitle}>
        Benefits of working
        {' '}
        <br />
        <i>with us</i>
      </div>
      <div className={styles.benefitsList}>
        {benefits.map(({
          imageUrl, title, text,
        }) => (
          <div
            key={title}
            className={styles.benefitsList__item}
          >
            <div className={styles.benefitsList__itemImage}>
              <PartLazyImage
                src={imageUrl}
                alt=""
              />
            </div>
            <div className={styles.benefitsList__titleAndText}>
              <p className={styles.benefitsList__itemTitle}>{title}</p>
              <p className={styles.benefitsList__itemText}>{text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
