import React from 'react';
import LazyLoad from 'react-lazy-load';

import PartLazyImage from '../../part-lazy-image';

import ImagePromoUrl from './assets/image-promo.webp';
import ImageVideoPlaceholder from './assets/image-video-placeholder.webp?placeholder';

import styles from './part-replacement-process.module.scss';

export default function PartReplacementProcess() {
  return (
    <div className={styles.partReplacementProcess}>
      <div className={styles.partReplacementProcess__title}>
        We’ll make your home
        {' '}
        <br />
        {' '}
        comfortable!
      </div>
      <div className={styles.partReplacementProcess__videoBannerBox}>
        <PartLazyImage
          className={styles.partReplacementProcess__videoBannerPromoImage}
          src={ImagePromoUrl}
          alt=""
        />
        <div className={styles.partReplacementProcess__videoBanner}>

          <LazyLoad offsetVertical={1000} debounce={false}>
            <video muted loop autoPlay poster={ImageVideoPlaceholder}>
              {/* video loading from '/public' folder */}
              <source src="/cut.mp4" type="video/mp4" />
            </video>
          </LazyLoad>
        </div>
      </div>
      <div className={styles.partReplacementProcess__description}>
        <p>
                    New generation energy-efficient windows and doors will make a HUGE improvement to your home’s comfort. Eliminating window drafts, gaps, and mold reduces heat loss and saves on energy costs. We manufacture windows and doors locally in Canada 🍁. SmartTech‘s Whole-Home Window Replacement usually takes under 8 hours* to complete!
        </p>
        <p>
          We carefully remove your old windows and doors and take them to our facility for
          recycling.
          Then we install your new windows and doors, clad and caulk the exterior,
          and install interior trim. All
          of our windows and doors are backed by a Lifetime Warranty.
        </p>
      </div>
    </div>
  );
}
